export function recursiveSwitchAriaHidden(elem, componentOpenState) {
    const parent = elem.parentNode
    const body = document.getElementsByTagName('body')[0]

    if (parent === body) {
        return
    }

    const brothers = parent.children
    for (const brother of brothers) {
        if (brother !== elem) {
            componentOpenState
                ? brother.setAttribute('aria-hidden', componentOpenState)
                : brother.removeAttribute('aria-hidden')
        }
    }
    recursiveSwitchAriaHidden(parent, componentOpenState)
}

export function focusin(e, el, loop) {
    if (!el.contains(e.target)) {
        if (el.lastFocused === el.firstFocusable) {
            loop ? el.lastFocusable.focus() : el.firstFocusable.focus()
        } else {
            loop ? el.firstFocusable.focus() : el.lastFocusable.focus()
        }
    } else {
        el.lastFocused = e.target
    }
}

export function setFirstAndLastFocusable(el) {
    const focusable = el.querySelectorAll('button, [href], input, select, textarea, [tabindex="0"]')
    el.firstFocusable = focusable[0]
    el.lastFocusable = focusable[focusable.length - 1]
    el.lastFocused = el.firstFocusable
}
