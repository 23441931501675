import createProps from '@kissui/helpers/src/props.helpers'
import { dispatchEvent } from '@kissui/helpers/src/assets/js/eventDispatch'
import viewportHelper from '@kissui/helpers/src/viewport.helpers'
import {
    recursiveSwitchAriaHidden,
    setFirstAndLastFocusable,
    focusin
} from '@kissui/helpers/src/assets/js/scopedKeyboardNav'
import { EVENT_SORT_BY_CHANGE, EVENT_POPIN_KEY_DOWN } from '@kissui/components'

class Dropdown extends HTMLElement {
    constructor() {
        super()
        this.isOpen = false
        this.selected = null
        this.buttonElement = null
        this.onClickOutside = this.onClickOutside.bind(this)
    }

    connectedCallback() {
        this.props = createProps(this.attributes)
        this.classList.add('nb-dropdown')
        this.render()
    }

    render() {
        this.buttonElement = null
        const { label = '', aa_description } = this.props
        let { options = [], element_id, element_name } = this.props

        // Accept Selected and Checked as option setter
        options = options.map(option => {
            const payload = { ...option }
            payload.checked = payload.checked || payload.selected

            return payload
        })

        this.unbindEvent()

        this.innerHTML = `
            <button
                class='nb-dropdown__button'
                aria-haspopup="listbox"
                aria-expanded="${this.isOpen}"
                aria-label="${label} ${options && this.renderSelected()}"
                ${aa_description !== '' ? `aria-description="${aa_description}"` : ''}>
                    <span class="nb-dropdown__button__label t-sm-700-sl">${label}</span>
                    <span class="nb-dropdown__button__value t-xs-500-sl"
                    ${element_id ? `name="${element_id}"` : ''}
                    value="${options && this.renderSelected()}">
                    </span>
                </button>
            <div class="nb-dropdown__menu" ${element_name ? `id="${element_name}"` : ''}>
                <div role="listbox">
                    ${options && options.length > 0 && this.renderOptions()}
                </div>
            </div>
        `
        this.buttonElement = this.querySelector('.nb-dropdown__button')
        this.buttonElementValue = this.querySelector('.nb-dropdown__button__value')
        this.dropdownMenu = this.querySelector('.nb-dropdown__menu > div')
        this.selectedOption = this.querySelector('button[aria-selected=true]')
        if (!this.selected) {
            this.selected = options[0]
        }
        setFirstAndLastFocusable(this.dropdownMenu)
        this.bindEvent()
    }

    // Render <BUTTON> tags for the custom select, <BUTTON> is better than <RADIOBUTTONS> for keyboard navigation users in this case
    renderOptions() {
        const {
            label,
            aa_listbox_description,
            aa_select_description,
            icon_option,
            options = []
        } = this.props
        return options
            .map(option => {
                return `<button
                    class="${viewportHelper.is.mobile ? 't-sm-400-sl' : 't-xs-500-sl'}"
                    role="option"
                    aria-label='${label + ' ' + option.label}'
                    value='${option.id ?? option.label}'
                    aria-selected='${option.checked}'
                    ${option.link ? `data-link='${option.link}'` : ''}
                    ${
                        option.checked
                            ? aa_listbox_description !== undefined &&
                              `aria-description="${aa_listbox_description}"`
                            : aa_select_description !== undefined &&
                              `aria-description="${aa_select_description}"`
                    }>
                  ${icon_option && `<nb-icon icon='${icon_option}'></nb-icon>`} ${option.label}
                </button>`
            })
            .join('')
    }

    // Render the selected option to display in the main button of the PLP Filter Bar
    renderSelected() {
        const { options = [] } = this.props
        const currentOption = options.find(
            option => option.checked === true || option.selected === true
        )
        return `${currentOption?.label || options[0]?.label}`
    }

    bindEvent() {
        this.boundHandleClick = this.handleClick.bind(this)
        this.boundHandleLabelClick = this.handleLabelClick.bind(this)
        this.boundHandleMobileClose = this.handleMobileClose.bind(this)
        this.boundFocus = this.focus.bind(this)
        this.boundFocusin = this.focusin.bind(this)
        this.boundKeyNav = this.keyNav.bind(this)

        this.buttonElement.addEventListener('click', this.boundHandleClick)
        this.querySelectorAll('.nb-dropdown__menu button').forEach(item => {
            item.addEventListener('click', this.boundHandleLabelClick)
        })
        this.dropdownMenu.addEventListener('click', this.boundHandleMobileClose)
        window.addEventListener('click', this.onClickOutside)
    }

    unbindEvent() {
        if (!this.buttonElement) {
            return
        }
        this.buttonElement.removeEventListener('click', this.boundHandleClick)
        this.querySelectorAll('.nb-dropdown__menu button').forEach(item => {
            item.removeEventListener('click', this.boundHandleLabelClick)
        })
        this.dropdownMenu.removeEventListener('click', this.boundHandleMobileClose)
        window.removeEventListener('click', this.onClickOutside)
        document.removeEventListener(EVENT_POPIN_KEY_DOWN, this.boundKeyNav)
    }

    handleClick() {
        this.toogleDropdown(!this.isOpen)
    }

    handleMobileClose() {
        this.toogleDropdown(false)
    }

    onClickOutside(e) {
        if (this.isOpen && !this.contains(e.target)) {
            this.toogleDropdown(false)
        }
    }

    handleLabelClick(e) {
        this.isOpen = false

        // Unpress active button
        if (this.selectedOption) {
            this.selectedOption.setAttribute('aria-selected', 'false')
        }

        /*
        * TODO CHECK WITH THOMAS
        const activeButton = this.querySelector('button[aria-pressed=true]')
        const activeLink = activeButton.getAttribute('data-link')
        if (activeLink) {
            window.location.href = activeLink
            return null
        }
        activeButton.setAttribute('aria-pressed', 'false')
        * */

        // Activate pressed option
        this.selectedOption = e.target
        this.selectedOption.setAttribute('aria-selected', 'true')

        // Reset all checked attributes to false / Destroy the object and build it again
        this.props.options = this.props.options.map(option => ({ ...option, checked: false }))

        // Search the option to be updated and assign the new value
        this.selected = this.props.options.find(
            option => option.label === this.selectedOption.value
        )
        this.selected.checked = true
        e.target.closest('.nb-dropdown').classList.add('nb-dropdown--is-filled')

        this.reRender()

        this.toogleDropdown(false)

        dispatchEvent({
            eventName: EVENT_SORT_BY_CHANGE,
            args: { options: this.props.options, selected: this.selected },
            element: this
        })
    }

    reRender() {
        const { label, aa_listbox_description, aa_select_description } = this.props
        this.buttonElementValue.setAttribute('value', this.selectedOption.value)
        this.buttonElement.setAttribute('aria-label', label + ' ' + this.renderSelected())
        this.dropdownMenu.querySelectorAll('button').forEach(item => {
            item.setAttribute('aria-description', aa_select_description)
        })
        this.selectedOption.setAttribute('aria-description', aa_listbox_description)
    }

    setSelected(value, dispatchChange = false) {
        const { options = [] } = this.props
        const tmpSelected = options.find(option => option.value === value)
        if (tmpSelected) {
            this.selected.checked = false
            this.selected = tmpSelected
            this.selected.checked = true
        }

        if (this.selectedOption) {
            this.selectedOption.setAttribute('aria-selected', 'false')
        }

        this.dropdownMenu.querySelectorAll('button').forEach(item => {
            if (item.value === this.selected.label) {
                this.selectedOption = item
                this.selectedOption.setAttribute('aria-selected', 'true')
            }
        })

        this.classList.add('nb-dropdown--is-filled')

        if (this.selectedOption) {
            this.reRender()
        }

        if (dispatchChange) {
            dispatchEvent({
                eventName: EVENT_SORT_BY_CHANGE,
                args: { options, selected: this.selected },
                element: this
            })
        }
    }

    toogleDropdown(value) {
        value ? this.open() : this.close()
        this.isOpen = !this.isOpen

        // Open and Close shared actions
        this.switchAriaModal()
        this.buttonElement.setAttribute('aria-expanded', this.isOpen)
    }

    switchAriaModal() {
        // This is the future way to scope the SR focus to a modal, but it's not supported yet by all SR
        this.dropdownMenu.setAttribute('aria-modal', this.isOpen)
        // So we need to set aria-hidden=false to all brothers and brothers of parent until body
        recursiveSwitchAriaHidden(this.dropdownMenu, this.isOpen)
    }

    open() {
        // Transitionend listener is fired multiple times (fore each css property)
        // so we need to instanciate it only when we open or close and then we remove it on focusin
        this.dropdownMenu.addEventListener('transitionend', this.boundFocus)
        document.addEventListener('focusin', this.boundFocusin)
        document.addEventListener(EVENT_POPIN_KEY_DOWN, this.boundKeyNav)
        this.buttonElement.classList.add('isOpen')
        this.openingSource = document.activeElement
    }

    close() {
        // Transitionend listener is fired multiple times (fore each css property)
        // so we need to instantiate it only when we open or close and then we remove it on focusin
        this.dropdownMenu.addEventListener('transitionend', this.boundFocus)
        document.removeEventListener('focusin', this.boundFocusin)
        document.removeEventListener(EVENT_POPIN_KEY_DOWN, this.boundKeyNav)
        this.buttonElement.classList.remove('isOpen')
        this.buttonElement.setAttribute('aria-expanded', false)
    }

    focus() {
        this.dropdownMenu.removeEventListener('transitionend', this.boundFocus)
        if (this.isOpen && this.selectedOption) {
            this.selectedOption.focus()
            this.dropdownMenu.lastFocused = this.selectedOption
        } else {
            this.buttonElement.focus()
        }
    }

    focusin(e) {
        const loop = false
        focusin(e, this.dropdownMenu, loop)
    }

    keyNav(e) {
        switch (e.key) {
            case 'Escape':
                this.toogleDropdown(false)
                break
            case 'ArrowUp':
                e.preventDefault()
                this.prevOption()
                break
            case 'ArrowDown':
                e.preventDefault()
                this.nextOption()
                break
            case 'Home':
            case 'PageUp':
                e.preventDefault()
                this.firstOption()
                break
            case 'End':
            case 'PageDown':
                e.preventDefault()
                this.lastOption()
                break
            default:
                break
        }
    }

    prevOption() {
        if (this.dropdownMenu.lastFocused !== this.dropdownMenu.firstFocusable) {
            const prevSibling = this.dropdownMenu.lastFocused.previousElementSibling
            prevSibling.focus()
            this.dropdownMenu.lastFocused = prevSibling
        }
    }

    nextOption() {
        if (this.dropdownMenu.lastFocused !== this.dropdownMenu.lastFocusable) {
            const nextSibling = this.dropdownMenu.lastFocused.nextElementSibling
            nextSibling.focus()
            this.dropdownMenu.lastFocused = nextSibling
        }
    }

    firstOption() {
        if (this.dropdownMenu.lastFocused !== this.dropdownMenu.firstFocusable) {
            this.dropdownMenu.firstFocusable.focus()
            this.dropdownMenu.lastFocused = this.dropdownMenu.firstFocusable
        }
    }

    lastOption() {
        if (this.dropdownMenu.lastFocused !== this.dropdownMenu.lastFocusable) {
            this.dropdownMenu.lastFocusable.focus()
            this.dropdownMenu.lastFocused = this.dropdownMenu.lastFocusable
        }
    }

    disconnectedCallback() {
        this.unbindEvent()
    }
}

customElements.get('nb-dropdown') || customElements.define('nb-dropdown', Dropdown)

export default Dropdown
